import type { ContentQueryItem } from './types'

const descriptionContent = `With 20 live channels and over 100 on demand content partners, RainberryTV offers viewers an exciting viewing experience published around the world. Our content spans entertainment news, new music discovery, world news, sports, movie trailers, gaming, viral videos, and more.

Our user base of over 65 million is one of the largest for any streaming video platform. Whether you’re looking to tune in and find out what’s happening, or just relaxing and want to enjoy some fun video content, RainberryTV delivers.
    
You won’t find passwords, subscriptions, payments needed to use our service, and it’s free for everyone who enjoys great content. So just lean back and enjoy!`

const iconUrl = 'https://media.unreel.me/prod/rainberrytv/general/b561d377-db16-4efc-a6a5-f88ee6fa6887'

const siteTitle = 'RainberryTV'

const siteUrl = 'https://www.rainberrytv.com/pages/home/d/on-demand'

const siteImage = 'https://media.unreel.me/prod/rainberrytv/general/06a2a691-70fe-4a7e-890c-a7d6d69c9f7d'

export const metaInfo = {
  descriptionContent,
  iconUrl,
  siteTitle,
  siteImage,
  siteUrl,
}

export const CONTENT_QUERY_LIST: ContentQueryItem[] = [
  { label: 'Movies', type: 'new-releases-movies', href: '/channel/new-releases-movies' },
  { label: 'Shows', type: 'new-releases-shows', href: '/channel/new-releases-shows' },
  { label: 'Games', type: 'new-releases-games', href: '/channel/new-releases-games' },
  { label: 'Animation', type: 'animation', href: '/channel/animation' },
  { label: 'Anime', type: 'anime', href: '/channel/anime' },
  { label: 'Comic Heroes', type: 'comic-heroes', href: '/channel/comic-heroes' },
  { label: 'Documentary', type: 'documentary', href: '/channel/documentary' },
  { label: 'Fantasy', type: 'fantasy', href: '/channel/fantasy' },
  { label: 'Horror', type: 'horror', href: '/channel/horror' },
  { label: 'Musical', type: 'musical', href: '/channel/musical' },
  { label: 'Reality-TV', type: 'reality-tv', href: '/channel/reality-tv' },
  { label: 'Sci-Fi', type: 'sci-fi', href: '/channel/sci-fi' },
]

export const maxContentSize = 100
