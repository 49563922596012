<script setup lang="ts">
import { CONTENT_QUERY_LIST } from '~/constants'

const links: { href: string, label: string, icon?: string }[] = [
  { href: '/', label: 'Home', icon: 'i-heroicons-home' },
  ...CONTENT_QUERY_LIST.map(({ label, href }) => ({ href, label })),
  { href: '/about', label: 'About', icon: 'i-heroicons-information-circle' },
  { href: '/privacy-policy', label: 'Privacy Policy', icon: 'i-heroicons-user' },
]
</script>

<template>
  <HeadlessMenu as="div" class="relative inline-block text-left">
    <HeadlessMenuButton>
      <Icon name="foundation:list" size="2rem" class="mr-2 transition cursor-pointer hover:text-green-400" />
    </HeadlessMenuButton>

    <Transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <HeadlessMenuItems
        class="absolute left-0 mt-4 w-56 origin-top-right  rounded-md bg-[#111] shadow-lg ring-1 ring-black/5 focus:outline-none "
      >
        <HeadlessMenuItem
          v-for="link in links"
          :key="link.href"
          v-slot="{ close }"
          as="section"
          class="p-1"
        >
          <NuxtLink :to="link.href" active-class="text-green-400">
            <div class="flex items-center gap-2 p-2 rounded hover:bg-gray-100/10" @click="close">
              <Icon :name="link.icon || 'i-heroicons-rss-solid' " size="1.2rem" />
              {{ link.label }}
            </div>
          </NuxtLink>
        </HeadlessMenuItem>
      </HeadlessMenuItems>
    </Transition>
  </HeadlessMenu>
</template>
