<script setup lang="ts">
import { metaInfo } from '~/constants'

useHead({
  htmlAttrs: { lang: 'en' },
  titleTemplate: titleChunk => titleChunk ? `${titleChunk} | ${metaInfo.siteTitle}` : metaInfo.siteTitle,
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { name: 'description', content: metaInfo.descriptionContent },
    { property: 'og:type', content: 'website' },
    { property: 'og:site:name', content: 'rainberrytv' },
    { property: 'og:title', content: metaInfo.siteTitle },
    { property: 'og:url', content: metaInfo.siteUrl },
    { property: 'og:image', content: metaInfo.siteImage },
    { property: 'og:image:width', content: 480 },
    { property: 'og:image:height', content: 270 },
    { property: 'og:description', content: metaInfo.descriptionContent },
    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:title', content: metaInfo.siteTitle },
    { name: 'twitter:url', content: metaInfo.siteUrl },
    { name: 'twitter:image', content: metaInfo.siteImage },
    { name: 'twitter:description', content: metaInfo.descriptionContent },
  ],
  link: [
    {
      rel: 'shortcut icon',
      href: metaInfo.iconUrl,
      type: 'image/png',
    },
  ],
})

onMounted(() => {
  requestPlacements()
})
</script>

<template>
  <Header />
  <div class="overflow-x-hidden overflow-y-auto mt-[100px] w-full h-[calc(100vh-100px)]">
    <NuxtPage />
    <ClientOnly>
      <Footer />
    </ClientOnly>
  </div>
</template>

<style>
html, body , #__nuxt{
  height: 100vh;
  margin: 0;
  padding: 0;
  background: #111;
  color: white;
  overflow: hidden;
}
</style>
